<template>
    <div style="display: flex; flex-direction: row; gap: 8px">
        <div style="display: flex; flex-direction: row; align-items: center; gap: 8px">
            <div :class="{ 'activeCircle': active === 0, 'doneCircle': active > 0 }" style="padding: 5px; border-radius: 100px; display: flex; justify-content: center;">
                <i style="color: white; font-size: 0.8rem" class="pi pi-pencil"></i>
            </div>
            <div :class="{ 'activeText': active === 0, 'doneText': active > 0 }" style="font-weight: 700;">
                {{ this.$langGetValue('biaStatus1') }}
            </div>
        </div>
        <Divider layout="horizontal" />
        <div style="display: flex; flex-direction: row; align-items: center; gap: 8px">
            <div :class="{ 'activeCircle': active === 1, 'inactiveCircle': active !== 1 && active < 1, 'doneCircle': active !== 1 && active > 1}" style="padding: 5px; border-radius: 100px; display: flex; justify-content: center;">
                <i style="color: white; font-size: 0.8rem" class="pi pi-pencil"></i>
            </div>
            <div :class="{ 'activeText': active === 1, 'inactiveText': active !== 1 && active < 1, 'doneText': active !== 1 && active > 1}" style="font-weight: 700;">
                {{ this.$langGetValue('biaStatus2') }}
            </div>
        </div>
        <Divider layout="horizontal" />
        <div style="display: flex; flex-direction: row; align-items: center; gap: 8px; justify-content: end">
            <div :class="{ 'activeCircle': active === 2, 'inactiveCircle': active !== 2 && active < 2, 'doneCircle': active !== 2 && active > 2 }" style="padding: 5px; border-radius: 100px; display: flex; justify-content: center;">
                <i style="color: white; font-size: 0.8rem" class="pi pi-pencil"></i>
            </div>
            <div :class="{ 'activeText': active === 2, 'inactiveText': active !== 2 && active < 2, 'doneText': active !== 2 && active > 2 }" style="font-weight: 700;">
                {{ this.$langGetValue('biaStatus3') }}
            </div>
        </div>
        <Divider layout="horizontal" />
        <div style="display: flex; flex-direction: row; align-items: center; gap: 8px">
            <div :class="{ 'activeCircle': active === 3, 'inactiveCircle': active !== 3 && active < 3, 'doneCircle': active !== 3 && active > 3}" style="padding: 5px; border-radius: 100px; display: flex; justify-content: center;">
                <i style="color: white; font-size: 0.8rem" class="pi pi-pencil"></i>
            </div>
            <div :class="{ 'activeText': active === 3, 'inactiveText': active !== 3 && active < 3, 'doneText': active !== 3 && active > 3 }" style="font-weight: 700;">
                {{ this.$langGetValue('biaStatus4') }}
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import "../Global.css";

  
  export default {
    components: {

    },
    props: {
        statusInput: Number,
    },
    data() {
      return {
        active: null,
        status: [
            {
                id: 0, label: 'toFill', statusId: [1, 5, 6]
            },
            {
                id: 1, label: 'pendingBpmApproval', statusId: [2]
            },
            {
                id: 2, label: 'pendingRoApproval', statusId: [3]
            },
            {
                id: 3, label: 'closed', statusId: [4]
            },
        ],
      };
    },
    created() {
        if(this.statusInput == 'reFill'){
            this.active = this.status[0].id
            return
        }

       this.status.forEach((item)=>{
            if(item.label === this.statusInput || item.statusId.includes(this.statusInput)){
                this.active = item.id
                return
            }
       })
    },
    methods: {},
  };
  </script>
  <style>

  .activeText{
    font-size: 12px;
    font-weight: 700;
    color: var(--color_gray_dark);
  }

  .inactiveText{
    font-size: 10px;
    font-weight: 500;
    color: var(--color_gray_light);
  }

  .doneText{
    font-size: 10px;
    font-weight: 500;
    color: var(--color_gray_medium);
  }

  .activeCircle{
    background-color: var(--color_brand_medium);
  }

  .inactiveCircle{
    background-color: var(--color_gray_light); 
  }

  .doneCircle{
    background-color: var(--color_gray_dark); 
  }
  
  </style>
  